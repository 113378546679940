import * as React from 'react'
import VideoPage from "../../components/VideoPage/VideoPage";

const ukgraine = () => {
    return (
        <VideoPage
            url="https://tfproductionmediaservice-euwe.streaming.media.azure.net/5c0a9b5e-fd03-4575-80e2-6730da16b28a/Emso - Ukraine.ism/manifest"
        />
    )
}

export default ukgraine;